import {mapState, mapGetters, mapActions, mapMutations} from 'vuex'

export const authComputed = {
    ...mapState('auth', {
        currentUser: (state) => state.currentUser,
    }),
    ...mapGetters('auth', ['loggedIn']),
}

export const layoutComputed = {
    ...mapState('layout', {
        layoutType: (state) => state.layoutType,
        layoutScrollable: (state) => state.layoutScrollable,
        layoutMode: (state) => state.layoutMode,
        leftSidebarType: (state) => state.leftSidebarType,
        sidebarColor: (state) => state.sidebarColor,
        layoutWidth: (state) => state.layoutWidth,
        topbar: (state) => state.topbar
    })
}

export const loggedInfoObject = {
    ...mapState('loggedInfo', {
        logged_info: (state) => state.logged_info
    }),
    ...mapGetters('loggedInfo', ['print_logged_info'])
}

export const loggedInfoMethods = mapMutations('loggedInfo', ['setLoggedInfo', 'setAuth', 'setInitInfo'])

export const groupInfoList = {
    ...mapState('groupName', {
        appGroupName: (state) => state.appGroupName
    }),
    ...mapGetters('groupName', ['printGroupName'])
}

export const groupInfoMethod = mapMutations('groupName', ['setGroupList'])

export const authMethods = mapActions('auth', ['logIn', 'logOut', 'register', 'resetPassword'])

export const layoutMethods = mapActions('layout', ['changeLayoutType', 'changeLayoutMode', 'changeLayoutWidth', 'changeLeftSidebarType', 'changeTopbar', 'changeSidebarColor', 'changeLayoutPosition'])

export const authFackMethods = mapActions('authfack', ['login', 'registeruser', 'logout'])

export const notificationMethods = mapActions('notification', ['success', 'error', 'clear'])

export const todoComputed = {
    ...mapState('todo', {
        todos: (state) => state.todos
    })
}
export const todoMethods = mapActions('todo', ['fetchTodos'])
